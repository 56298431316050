import React from 'react';
import TypeOfDemandDrawerButton from '@components/AdvanceSearchDrawer/Button/TypeOfDemandButton';
import RealEstateTypeDrawerButton from '@components/AdvanceSearchDrawer/Button/RealEstateTypeButton';
import CityButton from '@components/AdvanceSearchDrawer/Address/CityButton';
import DistrictButton from '@components/AdvanceSearchDrawer/Address/DistrictButton';
import WardButton from '@components/AdvanceSearchDrawer/Address/WardButton';
import StreetButton from '@components/AdvanceSearchDrawer/Address/StreetButton';
import MyButton from '@components/Button';
import AreaDrawerButton from '@components/AdvanceSearchDrawer/Button/AreaButton';
import PriceDrawerButton from '@components/AdvanceSearchDrawer/Button/PriceButton';
import HasAlleyDrawerButton from '@components/AdvanceSearchDrawer/Button/HasAlleyButton';
import WidthDrawerButton from '@components/AdvanceSearchDrawer/Button/WidthButton';
import LengthDrawerButton from '@components/AdvanceSearchDrawer/Button/LengthButton';
import RearDrawerButton from '@components/AdvanceSearchDrawer/Button/RearButton';
import BedroomDrawerButton from '@components/AdvanceSearchDrawer/Button/BedroomButton';
import ToiletDrawerButton from '@components/AdvanceSearchDrawer/Button/ToiletButton';
import FloorDrawerButton from '@components/AdvanceSearchDrawer/Button/FloorButton';
import UtilitiesDrawerButton from '@components/AdvanceSearchDrawer/Button/UtilitiesButton';
import { useFormContext } from 'react-hook-form';
import {
  convertTypeHomeToProject,
  convertTypeHomeToRent,
  convertTypeHomeToSale,
  defaultConfigs,
} from '@components/AdvanceSearch/configs';
import { pickBy } from 'lodash';
import { allPostType } from '@utils/configs/SearchList/SearchListConfigs';
import { useRouter } from 'next/router';
import {
  getRealEstateType,
  getRealEstateType2,
} from '@components/AdvanceSearch/components/AdvanceDropdown';
import useDeepCompareEffect from 'react-use/lib/useDeepCompareEffect';
import { convertPrice } from '@components/AdvanceSearch/components/RenderRow';
import InnerAdvanceSearchWrapper from '@components/AdvanceSearchDrawer/InnerAdvanceSearchWrapper';
import { routeSearchList } from '@utils/configs/SearchList/const';
type AdvanceSearchDrawerProps = {
  onToggleDrawer?: Function,
  isMap?: Boolean,
  setVariablesFilterOnMobile?: Function,
};
export default function AdvanceSearchDrawer({
  onToggleDrawer,
  isMap = false,
  setVariablesFilterOnMobile,
}: AdvanceSearchDrawerProps) {
  const { reset, watch } = useFormContext();
  const router = useRouter();
  const isForSell = watch('isForSell'),
    realEstateType = watch('realEstateType'),
    searchString = watch('searchString'),
    province = watch('province'),
    district = watch('district'),
    ward = watch('ward'),
    street = watch('street'),
    areaFrom = watch('areaFrom'),
    areaTo = watch('areaTo'),
    priceFrom = watch('priceFrom'),
    priceTo = watch('priceTo'),
    hasAlley = watch('hasAlley'),
    widthFrom = watch('widthFrom'),
    widthTo = watch('widthTo'),
    lengthFrom = watch('lengthFrom'),
    lengthTo = watch('lengthTo'),
    rearFrom = watch('rearFrom'),
    rearTo = watch('rearTo'),
    bedroomCountFrom = watch('bedroomCountFrom'),
    bedroomCountTo = watch('bedroomCountTo'),
    toiletCountFrom = watch('toiletCountFrom'),
    toiletCountTo = watch('toiletCountTo'),
    floorCountFrom = watch('floorCountFrom'),
    floorCountTo = watch('floorCountTo'),
    utilities = watch('utilities');
  const commonIsForSell =
    router.query?.isForSell?.length > 0
      ? router.query?.isForSell
      : router.query?.typeOfDemand || 'ForSale';
  const realEstateTypeParams = Array.isArray(router.query?.realEstateTypes)
    ? router.query?.realEstateTypes
    : router.query?.realEstateTypes?.length > 0
    ? [router.query?.realEstateTypes]
    : router.query?.realEstateType === 'allRealEstateType' &&
      ['ForRent', 'ToRent'].includes(commonIsForSell)
    ? [...convertTypeHomeToRent]
    : router.query?.realEstateType === 'allRealEstateType' &&
      ['ForSale', 'ToBuy'].includes(commonIsForSell)
    ? [...convertTypeHomeToSale]
    : router.query?.realEstateType === 'allProjectType'
    ? [...convertTypeHomeToProject]
    : [router.query?.realEstateType || 'townhouse'];
  useDeepCompareEffect(() => {
    reset({
      isForSell:
        commonIsForSell === 'project' ? 'Project' : commonIsForSell?.toString(),
      realEstateType: realEstateTypeParams,
      province: router.query.province || defaultConfigs.province,
      district: router.query.district || '',
      ward: router.query.ward || '',
      street: router.query.street || '',
      areaFrom: !router.query.areaFrom ? 0 : router.query.areaFrom,
      areaTo: !router.query.areaTo ? 20001 : router.query.areaTo,
      priceFrom: !router.query?.priceFrom
        ? 0
        : commonIsForSell === 'ForRent' || commonIsForSell === 'ToRent'
        ? parseInt(router.query?.priceFrom, 10) / Math.pow(10, 6)
        : parseInt(router.query?.priceFrom, 10) / Math.pow(10, 9),
      priceTo: convertPrice(router.query?.priceTo, commonIsForSell),
      hasAlley:
        router.query.hasAlley === 'true'
          ? 'yes'
          : router.query.hasAlley === 'false'
          ? 'no'
          : '',
      widthFrom: !router.query.widthFrom
        ? 0
        : parseInt(router.query.widthFrom, 10),
      widthTo: !router.query.areaTo ? 101 : parseInt(router.query.widthTo, 10),
      lengthFrom: !router.query.lengthFrom
        ? 0
        : parseInt(router.query.lengthFrom, 10),
      lengthTo: !router.query.lengthTo
        ? 501
        : parseInt(router.query.lengthTo, 10),
      rearFrom: !router.query.rearFrom
        ? 0
        : parseInt(router.query.rearFrom, 10),
      rearTo: !router.query.areaTo ? 101 : parseInt(router.query.rearTo, 10),
      bedroomCountFrom: !router.query.bedroomCountFrom
        ? 0
        : parseInt(router.query.bedroomCountFrom, 10),
      bedroomCountTo: !router.query.bedroomCountTo
        ? 169
        : parseInt(router.query.bedroomCountTo, 10),
      toiletCountFrom: !router.query.toiletCountFrom
        ? 0
        : parseInt(router.query.toiletCountFrom, 10),
      toiletCountTo: !router.query.toiletCountTo
        ? 169
        : parseInt(router.query.toiletCountTo, 10),
      floorCountFrom: !router.query.floorCountFrom
        ? 0
        : parseInt(router.query.floorCountFrom, 10),
      floorCountTo: !router.query.floorCountTo
        ? 169
        : parseInt(router.query.floorCountTo, 10),
      utilities: Array.isArray(router.query.utilities)
        ? router.query.utilities
        : router.query.utilities?.length > 0
        ? [router.query.utilities]
        : ['pool', 'garden', 'lift', 'smartKey', 'storehouse'],
    });
  }, [router, reset]);

  const variablesAllField = pickBy(
    {
      isForSell: isForSell === 'Project' ? 'project' : isForSell,
      realEstateTypes: getRealEstateType({ isForSell, realEstateType }),
      searchString,
      province,
      district,
      ward,
      street,
      areaTo: areaTo === 20001 ? null : areaTo,
      areaFrom: areaFrom === 0 ? null : areaFrom,
      priceFrom:
        priceFrom === 0
          ? null
          : isForSell === 'ForRent' || isForSell === 'ToRent'
          ? priceFrom * Math.pow(10, 6)
          : priceFrom * Math.pow(10, 9),
      priceTo:
        priceTo === 101 || priceTo === 200.5
          ? null
          : isForSell === 'ForRent' || isForSell === 'ToRent'
          ? priceTo * Math.pow(10, 6)
          : priceTo * Math.pow(10, 9),
      hasAlley: hasAlley === 'yes' ? true : hasAlley === 'no' ? false : '',
      widthFrom: widthFrom === 0 ? null : widthFrom,
      widthTo: widthTo === 101 ? null : widthTo,
      lengthFrom: lengthFrom === 0 ? null : lengthFrom,
      lengthTo: lengthTo === 501 ? null : lengthTo,
      rearFrom: rearFrom === 0 ? null : rearFrom,
      rearTo: rearTo === 101 ? null : rearTo,
      bedroomCountFrom: bedroomCountFrom === 0 ? null : bedroomCountFrom,
      bedroomCountTo: bedroomCountTo === 169 ? null : bedroomCountTo,
      toiletCountFrom: toiletCountFrom === 0 ? null : toiletCountFrom,
      toiletCountTo: toiletCountTo === 169 ? null : toiletCountTo,
      floorCountFrom: floorCountFrom === 0 ? null : floorCountFrom,
      floorCountTo: floorCountTo === 169 ? null : floorCountTo,
      utilities,
    },
    (e) => e || e === false,
  );

  const variablesOnMap = pickBy(
    {
      typeOfDemand: isForSell === 'Project' ? 'project' : isForSell,
      realEstateType: getRealEstateType({ isForSell, realEstateType }),
      areaTo: areaTo === 20001 ? null : areaTo,
      areaFrom: areaFrom === 0 ? null : areaFrom,
      priceFrom:
        priceFrom === 0
          ? null
          : isForSell === 'ForRent' || isForSell === 'ToRent'
          ? priceFrom
          : priceFrom,
      priceTo:
        priceTo === 101 || priceTo === 200.5
          ? null
          : isForSell === 'ForRent' || isForSell === 'ToRent'
          ? priceTo
          : priceTo,
      hasAlley: hasAlley === 'yes' ? true : hasAlley === 'no' ? false : '',
      widthFrom: widthFrom === 0 ? null : widthFrom,
      widthTo: widthTo === 101 ? null : widthTo,
      lengthFrom: lengthFrom === 0 ? null : lengthFrom,
      lengthTo: lengthTo === 501 ? null : lengthTo,
      bedroomCountFrom: bedroomCountFrom === 0 ? null : bedroomCountFrom,
      bedroomCountTo: bedroomCountTo === 169 ? null : bedroomCountTo,
      toiletCountFrom: toiletCountFrom === 0 ? null : toiletCountFrom,
      toiletCountTo: toiletCountTo === 169 ? null : toiletCountTo,
      floorCountFrom: floorCountFrom === 0 ? null : floorCountFrom,
      floorCountTo: floorCountTo === 169 ? null : floorCountTo,
    },
    (e) => e || e === false,
  );

  const pathname = `/${routeSearchList}/${isForSell?.replace(
    'Project',
    'project',
  )}/${getRealEstateType2({ isForSell, realEstateType })}/${allPostType}`;
  const commonPathname =
    !router.pathname.includes(`/${routeSearchList}`) && router.pathname !== '/'
      ? router.pathname
      : pathname;
  return (
    <InnerAdvanceSearchWrapper
      renderRight={() => {
        return (
          <div
            onClick={() => reset({ ...defaultConfigs })}
            className="text-sm font-bold text-blue-main">
            Đặt lại
          </div>
        );
      }}
      title="Bộ lọc & tìm kiếm"
      close={() => onToggleDrawer(false)}>
      <div className="w-full h-full bg-white px-3 pt-2 mb-28 space-y-6">
        <TypeOfDemandDrawerButton isMap={isMap} />
        <RealEstateTypeDrawerButton />
        {!isMap && (
          <>
            <CityButton />
            <DistrictButton />
            <WardButton />
            <StreetButton />
          </>
        )}
        <AreaDrawerButton />
        <PriceDrawerButton />
        <HasAlleyDrawerButton />
        <WidthDrawerButton />
        <LengthDrawerButton />
        {!isMap && <RearDrawerButton />}
        <BedroomDrawerButton />
        <ToiletDrawerButton />
        <FloorDrawerButton />
        {!isMap && <UtilitiesDrawerButton />}
      </div>
      <div className="w-full fixed bottom-0 px-3 p-3 bg-white">
        <MyButton
          onPress={async () => {
            if (isMap) {
              setVariablesFilterOnMobile({ ...variablesOnMap });
              await router.push({
                pathname: commonPathname,
                query: {
                  ...variablesOnMap,
                  isForSell: variablesOnMap.typeOfDemand,
                  realEstateTypes: variablesOnMap.realEstateType,
                  priceFrom:
                    priceFrom === 0
                      ? null
                      : variablesOnMap.typeOfDemand === 'ForRent' ||
                        variablesOnMap.typeOfDemand === 'ToRent'
                      ? priceFrom * Math.pow(10, 6)
                      : priceFrom * Math.pow(10, 9),
                  priceTo:
                    priceTo === 101 || priceTo === 200.5
                      ? null
                      : variablesOnMap.typeOfDemand === 'ForRent' ||
                        variablesOnMap.typeOfDemand === 'ToRent'
                      ? priceTo * Math.pow(10, 6)
                      : priceTo * Math.pow(10, 9),
                },
              });
            } else {
              await router.push({
                pathname: commonPathname,
                query: { ...variablesAllField },
              });
            }
            onToggleDrawer(false);
          }}
          type="primary"
          label="Tìm kiếm"
        />
      </div>
    </InnerAdvanceSearchWrapper>
  );
}
