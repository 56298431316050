import Label from '@components/pages/PublishRealEstate/components/Lable';
import { Controller, useFormContext } from 'react-hook-form';
import React, { useState } from 'react';
import styles from '@styles/components/PublistRealEstate/DropdownForPublish.module.scss';
import { Content } from '@components/pages/PublishRealEstate/components/MyDropdownForPublish';
import configs from '@utils/configs/RealEstateConfiguarations/RealEstateCommonConfigs.json';
import isEmpty from 'lodash/isEmpty';
import DrawerMultiSelection from '@components/DrawerMultiSelection';
import DrawerWrapper from '@components/DrawerWrapper';
export default function RealEstateTypeDrawerButton() {
  const { control, watch, setValue } = useFormContext();
  const { isForSell } = watch();

  const {
    forSellRealEstateTypes,
    forRentRealEstateTypes,
    projectForSaleTypes,
  } = configs;
  const isProject = isForSell === 'Project';
  const realEstateSelections = isProject
    ? projectForSaleTypes
    : isForSell === 'ForSale' || isForSell === 'ToBuy'
    ? forSellRealEstateTypes
    : forRentRealEstateTypes;
  return (
    <div className="w-full space-y-1">
      <Label labelContainerClassName="w-120px" label="Loại bất động sản" />
      <Controller
        control={control}
        name="realEstateType"
        render={({ field: { value, onChange } }) => {
          return (
            <RealEstateButton
              key={Math.random()}
              value={value}
              onChange={onChange}
              setValue={setValue}
              realEstateSelections={realEstateSelections}
            />
          );
        }}
      />
    </div>
  );
}
type RealEstateButtonProps = {
  value?: Array,
  realEstateSelections?: Array,
  onChange?: Function,
  setValue?: Function,
};
function RealEstateButton({
  value,
  realEstateSelections,
  onChange,
  setValue,
}: RealEstateButtonProps) {
  const [toggleDrawer, onToggleDrawer] = useState(false);
  return (
    <div className="w-full">
      <div
        onClick={() => onToggleDrawer(!toggleDrawer)}
        className={`${styles.buttonContainer} border-blue-main`}>
        <Content
          isBigArrow
          setDisableDropdown={onToggleDrawer}
          toggle={toggleDrawer}
          value={value}
          hideDeleteButton={value?.length === 0}
          customValue={() => {
            return (
              <div
                className={`flex-1 text-left text-sm truncate ${
                  isEmpty(value) ? 'text-gray-400' : 'text-blue-main'
                }`}>
                {value?.length === realEstateSelections?.length
                  ? 'Tất cả'
                  : value?.length > 0
                  ? value?.map((item, index) => {
                      return (
                        realEstateSelections?.find((e) => e.key === item)
                          ?.value +
                        `${
                          index === value?.length - 1 || value?.length === 1
                            ? '.'
                            : ' ,'
                        }`
                      );
                    })
                  : 'Chọn loại bất động sản'}
              </div>
            );
          }}
          onDelete={() => {
            setValue('realEstateType', []);
          }}
        />
      </div>
      {toggleDrawer && (
        <DrawerWrapper
          open={toggleDrawer}
          onClose={() => onToggleDrawer(false)}
          container={process.browser && document.getElementById('modal-root')}
          className="w-full h-full">
          <DrawerMultiSelection
            wrapperStyle="h-480px"
            title={'Loại bất động sản'}
            value={value}
            setValue={(nextValue) => onChange(nextValue)}
            configs={realEstateSelections}
            close={() => onToggleDrawer(false)}
          />
        </DrawerWrapper>
      )}
    </div>
  );
}
