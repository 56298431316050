import Label from '@components/pages/PublishRealEstate/components/Lable';
import { Controller, useFormContext } from 'react-hook-form';
import WardDropdown from '@components/AddressDropdown/WardDropdown';

export default function WardButton() {
  const { control, setValue, watch } = useFormContext();
  const district = watch('district');
  const street = watch('street');
  const province = watch('province');
  return (
    <div className="w-full space-y-1">
      <Label labelContainerClassName="w-120px" label="Phường/ Xã" />
      <Controller
        control={control}
        name="ward"
        render={({ field: { value, onChange } }) => {
          return (
            <div className="w-full">
              <WardDropdown
                provinceId={province}
                key={Math.random()}
                isBigArrow
                districtId={district}
                streetId={street}
                placeholder="Chọn giá trị"
                additionalItems={[{ id: '', name: 'Tất cả' }]}
                title="Phường/ Xã"
                setValue={(nextValue) => {
                  onChange(nextValue);
                }}
                value={value}
                onDelete={() => {
                  onChange('');
                  setValue('street', '');
                }}
              />
            </div>
          );
        }}
      />
    </div>
  );
}
