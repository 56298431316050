import React from 'react';

type LabelProps = {
  labelContainerClassName?: string,
  isRequire?: boolean,
  label: string,
  labelStyle?: string,
};
export default function Label({
  labelContainerClassName,
  isRequire,
  label,
  labelStyle = 'text-blue-main',
}: LabelProps) {
  return (
    <div className={`flex flex-row space-x-1 ${labelContainerClassName}`}>
      <div
        dangerouslySetInnerHTML={{
          __html: `${label} <b class="text-red-600 text-14">${
            isRequire ? '*' : ''
          }</b>`,
        }}
        className={`text-14 font-bold ${labelStyle}`}
      />
    </div>
  );
}
