import MyDropdownForPublish from '@components/pages/PublishRealEstate/components/MyDropdownForPublish';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Label from '@components/pages/PublishRealEstate/components/Lable';
import { hasAlleyConfig } from '@components/AdvanceSearch/configs';

export default function HasAlleyDrawerButton() {
  const { control } = useFormContext();
  return (
    <div className="w-full space-y-1">
      <Label labelContainerClassName="w-full" label="Mặt tiền/ Trong hẻm" />
      <Controller
        control={control}
        name="hasAlley"
        render={({ field: { value, onChange } }) => {
          return (
            <div className="w-full">
              <MyDropdownForPublish
                key={Math.random()}
                onDelete={() => onChange('')}
                title="Mặt tiền/ Trong hẻm"
                isBigArrow
                value={value}
                setValue={(value) => {
                  onChange(value);
                }}
                configs={hasAlleyConfig}
              />
            </div>
          );
        }}
      />
    </div>
  );
}
