import React from 'react';
import { useFormContext } from 'react-hook-form';
import configs from '@utils/configs/RealEstateConfiguarations/RealEstateFilterConfigs.json';
import ButtonDrawerSlider from '@components/ButtonDrawerSlider';
import useDeepCompareEffect from 'react-use/lib/useDeepCompareEffect';
export default function AreaDrawerButton() {
  const { setValue, watch } = useFormContext();

  const {
    areaKey,
    areaFrom,
    areaTo,
    widthFrom,
    widthTo,
    lengthFrom,
    lengthTo,
  } = watch();
  const { townhouse } = configs;
  const { area } = townhouse || {};
  const convertArea =
    area?.map((e) => {
      return {
        from: e.from,
        to: !e?.to ? 20001 : e.to,
        value: e.value,
        key: e.key,
        unit: e.unit,
      };
    }) || [];
  const isDefaultWidth = widthFrom === 0 && widthTo === 101;
  const isDefaultLength = lengthFrom === 0 && lengthTo === 501;
  useDeepCompareEffect(() => {
    if (!isDefaultWidth && !isDefaultLength) {
      if (widthTo === 101 && lengthTo === 501) {
        setValue('areaFrom', 5000);
        setValue('areaTo', 20001);
      } else {
        setValue('areaFrom', widthFrom * lengthFrom);
        setValue('areaTo', widthTo * lengthTo);
      }
    }
  }, [
    lengthFrom,
    lengthTo,
    widthFrom,
    widthTo,
    isDefaultLength,
    isDefaultWidth,
  ]);
  return (
    <ButtonDrawerSlider
      label="Diện tích"
      renderToValue={20000}
      isLong
      progress={2500.5}
      sliderTitle={'Diện tích (m²)'}
      stepRenderLine={800}
      key={Math.random() + areaFrom + areaTo}
      deleteValue={() => {
        setValue('areaKey', '');
        setValue('areaFrom', 0);
        setValue('areaTo', 20001);
        setValue('widthKey', '');
        setValue('widthFrom', 0);
        setValue('widthTo', 101);
        setValue('lengthKey', '');
        setValue('lengthFrom', 0);
        setValue('lengthTo', 501);
      }}
      exceptValue={
        (areaFrom === 0 || !areaFrom) && areaTo === 10 ? '< 10 m' : ''
      }
      theValueIsShow={5000}
      listSelections={convertArea || []}
      onSelectItem={({ from, to, key }) => {
        setValue('areaKey', key);
        setValue('areaFrom', from);
        setValue('areaTo', to);
        setValue('widthKey', '');
        setValue('widthFrom', 0);
        setValue('widthTo', 101);
        setValue('lengthKey', '');
        setValue('lengthFrom', 0);
        setValue('lengthTo', 501);
      }}
      keyValue={areaKey}
      from={areaFrom}
      to={areaTo}
      unit={'m²'}
      min={0}
      max={5000}
      step={1}
      additionalItems={[{ key: '', from: 0, to: 20001, value: 'Tất cả' }]}
    />
  );
}
