import React from 'react';
import { useQuery } from '@apollo/client';
import { GET_LIST_WARD_OF_DISTRICT } from '@schemas/queries/map';
import MyDropdownForPublish from '@components/pages/PublishRealEstate/components/MyDropdownForPublish';
import pickBy from 'lodash/pickBy';
import { HCMId } from '@utils/configs/Common/CommonConfigs';
type Props = {
  value?: string,
  setValue?: Function,
  buttonDropdownClassName?: string,
  placeholder?: string,
  onDelete?: Function,
  additionalItems?: Array,
  errorMsg?: string,
  districtId: string,
  streetId?: string,
  title?: string,
  isBigArrow?: boolean,
  disabled?: boolean,
  getObject?: boolean,
  provinceId?: string,
};

function convertDataSelections(data) {
  return data?.map((item) => ({ key: item.id, value: item.name, ...item }));
}
export default function WardDropdown(props: Props) {
  const {
    placeholder,
    value,
    setValue,
    buttonDropdownClassName,
    onDelete,
    additionalItems = [],
    errorMsg,
    districtId,
    streetId,
    title,
    isBigArrow = false,
    disabled,
    getObject = false,
    provinceId,
  } = props;
  const finalVariables = pickBy(
    {
      districtId,
      streetId: provinceId === HCMId ? streetId : null,
      limit: 100,
    },
    (e) => e,
  );
  const { data, loading } = useQuery(GET_LIST_WARD_OF_DISTRICT, {
    variables: {
      ...finalVariables,
    },
    skip: !districtId,
  });
  const rawData = [
    ...(additionalItems || []),
    ...(data?.getListWardsOfDistrict?.wards || []),
  ];
  const dataSelections = convertDataSelections(rawData);

  return (
    <MyDropdownForPublish
      getObject={getObject}
      loading={loading}
      isBigArrow={isBigArrow}
      title={title}
      isShowSearchBox={true}
      value={value}
      setValue={setValue}
      configs={dataSelections}
      placeholder={placeholder}
      buttonDropdownClassName={buttonDropdownClassName}
      errorMsg={errorMsg}
      onDelete={onDelete}
      disabled={disabled}
    />
  );
}
