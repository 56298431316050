import MyDropdownForPublish from '@components/pages/PublishRealEstate/components/MyDropdownForPublish';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Label from '@components/pages/PublishRealEstate/components/Lable';
import {
  convertTypeHomeToProject,
  options,
  optionsOnMap,
} from '@components/AdvanceSearch/configs';

type Props = {
  isMap?: Boolean,
};
export default function TypeOfDemandDrawerButton({ isMap }: Props) {
  const { control, setValue } = useFormContext();
  return (
    <div className="w-full space-y-1">
      <Label labelContainerClassName="w-120px" label="Nhu cầu" />
      <Controller
        control={control}
        name="isForSell"
        render={({ field: { value, onChange } }) => {
          return (
            <div className="w-full">
              <MyDropdownForPublish
                title="Nhu cầu"
                isBigArrow
                hideDeleteButton
                value={value}
                setValue={(value) => {
                  onChange(value);
                  if (value === 'ForRent' || value === 'ToRent') {
                    setValue('priceFrom', 0);
                    setValue('priceTo', 200.5);
                    setValue('priceKey', '');
                    setValue('realEstateType', ['townhouse']);
                  } else if (value === 'Project') {
                    setValue('realEstateType', [...convertTypeHomeToProject]);
                    setValue('priceFrom', 0);
                    setValue('priceTo', 101);
                    setValue('priceKey', '');
                  } else {
                    setValue('priceFrom', 0);
                    setValue('priceTo', 101);
                    setValue('priceKey', '');
                    setValue('realEstateType', ['townhouse']);
                  }
                }}
                configs={isMap ? optionsOnMap : options}
              />
            </div>
          );
        }}
      />
    </div>
  );
}
