import React from 'react';
import { useFormContext } from 'react-hook-form';
import configs from '@utils/configs/RealEstateConfiguarations/RealEstateFilterConfigs.json';
import ButtonDrawerSlider from '@components/ButtonDrawerSlider';
export default function BedroomDrawerButton() {
  const { setValue, watch } = useFormContext();
  const { bedroomCountKey, realEstateType, bedroomCountFrom, bedroomCountTo } =
    watch();
  const { townhouse } = configs;
  const { bedroomCount } = townhouse || {};
  const convertBedroomCount =
    bedroomCount?.map((e) => {
      return {
        from: e.from,
        to: !e?.to ? 169 : e.to,
        value: e.value,
        key: e.key,
        unit: e.unit,
      };
    }) || [];
  return (
    <ButtonDrawerSlider
      label="Số phòng ngủ"
      renderToValue={168}
      progress={84.5}
      sliderTitle={'Số lượng phòng'}
      key={Math.random() + bedroomCountFrom + bedroomCountTo}
      deleteValue={() => {
        setValue('bedroomCountKey', '');
        setValue('bedroomCountFrom', 0);
        setValue('bedroomCountTo', 169);
      }}
      exceptValue={
        bedroomCountFrom === 0 && bedroomCountTo === 3 ? '<= 3 phòng' : ''
      }
      disabled={
        realEstateType?.length === 1 &&
        (realEstateType?.[0] === 'building' || realEstateType?.[0] === 'land')
      }
      listSelections={convertBedroomCount || []}
      onSelectItem={({ from, to, key }) => {
        setValue('bedroomCountKey', key);
        setValue('bedroomCountFrom', from);
        setValue('bedroomCountTo', to);
      }}
      keyValue={bedroomCountKey}
      from={bedroomCountFrom}
      to={bedroomCountTo}
      unit={'phòng'}
      min={0}
      max={168}
      step={1}
      additionalItems={[{ key: '', from: 0, to: 169, value: 'Tất cả' }]}
    />
  );
}
