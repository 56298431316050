import React from 'react';
import { useQuery } from '@apollo/client';
import { GET_LIST_DISTRICT_OF_PROVINCE } from '@schemas/queries/map';
import MyDropdownForPublish from '@components/pages/PublishRealEstate/components/MyDropdownForPublish';
type Props = {
  value?: string,
  setValue?: Function,
  buttonDropdownClassName?: string,
  placeholder?: string,
  onDelete?: Function,
  additionalItems?: Array,
  errorMsg?: string,
  provinceId: string,
  title?: string,
  isBigArrow?: boolean,
  disabled?: boolean,
  getObject?: boolean,
};

function convertDataSelections(data) {
  return data?.map((item) => ({ key: item.id, value: item.name, ...item }));
}
export default React.forwardRef(DistrictDropdown);
function DistrictDropdown(props: Props, ref) {
  const {
    placeholder,
    value,
    setValue,
    buttonDropdownClassName,
    onDelete,
    additionalItems = [],
    errorMsg,
    provinceId,
    title,
    isBigArrow = false,
    disabled,
    getObject = false,
  } = props;
  const { data, loading } = useQuery(GET_LIST_DISTRICT_OF_PROVINCE, {
    variables: {
      provinceId,
      limit: 100,
    },
    skip: !provinceId,
  });
  const rawData = [
    ...(additionalItems || []),
    ...(data?.getListDistrictsOfProvince?.districts || []),
  ];
  const dataSelections = convertDataSelections(rawData);
  return (
    <MyDropdownForPublish
      ref={ref}
      getObject={getObject}
      loading={loading}
      disabled={disabled}
      isBigArrow={isBigArrow}
      title={title}
      isShowSearchBox={true}
      value={value}
      setValue={setValue}
      configs={dataSelections}
      placeholder={placeholder}
      buttonDropdownClassName={buttonDropdownClassName}
      errorMsg={errorMsg}
      onDelete={onDelete}
    />
  );
}
