import Label from '@components/pages/PublishRealEstate/components/Lable';
import { Controller, useFormContext } from 'react-hook-form';
import React, { useState } from 'react';
import styles from '@styles/components/PublistRealEstate/DropdownForPublish.module.scss';
import { Content } from '@components/pages/PublishRealEstate/components/MyDropdownForPublish';
import isEmpty from 'lodash/isEmpty';
import DrawerMultiSelection from '@components/DrawerMultiSelection';
import { utilitiesConfig } from '@components/AdvanceSearch/configs';
import DrawerWrapper from '@components/DrawerWrapper';
export default function UtilitiesDrawerButton() {
  const { control, setValue } = useFormContext();
  return (
    <div className="w-full space-y-1">
      <Label labelContainerClassName="w-full" label="Tiện ích" />
      <Controller
        control={control}
        name="utilities"
        render={({ field: { value, onChange } }) => {
          return (
            <UtilitiesButton
              value={value}
              setValue={setValue}
              onChange={onChange}
              key={Math.random()}
            />
          );
        }}
      />
    </div>
  );
}

type UtilitiesButtonProps = {
  value?: Array,
  onChange?: Function,
  setValue?: Function,
};

function UtilitiesButton({ value, onChange, setValue }: UtilitiesButtonProps) {
  const [toggleDrawer, onToggleDrawer] = useState(false);
  return (
    <div className="w-full">
      <div
        onClick={() => onToggleDrawer(!toggleDrawer)}
        className={`${styles.buttonContainer} border-blue-main`}>
        <Content
          isBigArrow
          setDisableDropdown={onToggleDrawer}
          toggle={toggleDrawer}
          value={value}
          hideDeleteButton={value?.length === 0}
          customValue={() => {
            return (
              <div
                className={`flex-1 text-left text-sm truncate ${
                  isEmpty(value) ? 'text-gray-400' : 'text-blue-main'
                }`}>
                {value?.length === utilitiesConfig?.length
                  ? 'Tất cả'
                  : value?.length > 0
                  ? value?.map((item, index) => {
                      return (
                        utilitiesConfig?.find((e) => e.key === item)?.value +
                        `${
                          index === value?.length - 1 || value?.length === 1
                            ? '.'
                            : ' ,'
                        }`
                      );
                    })
                  : 'Chọn giá trị'}
              </div>
            );
          }}
          onDelete={() => {
            setValue('utilities', []);
          }}
        />
      </div>
      {toggleDrawer && (
        <DrawerWrapper
          open={toggleDrawer}
          onClose={() => onToggleDrawer(false)}
          container={process.browser && document.getElementById('modal-root')}
          className="w-full h-full">
          <DrawerMultiSelection
            title={'Tiện ích bất động sản'}
            value={value}
            setValue={(nextValue) => onChange(nextValue)}
            configs={utilitiesConfig}
            close={() => onToggleDrawer(false)}
          />
        </DrawerWrapper>
      )}
    </div>
  );
}
