import React from 'react';
import { useFormContext } from 'react-hook-form';
import configs from '@utils/configs/RealEstateConfiguarations/RealEstateFilterConfigs.json';
import ButtonDrawerSlider from '@components/ButtonDrawerSlider';
export default function LengthDrawerButton() {
  const { setValue, watch } = useFormContext();
  const { lengthKey, lengthFrom, lengthTo } = watch();
  const { townhouse } = configs;
  const { length } = townhouse || {};
  const convertLength =
    length?.map((e) => {
      return {
        from: e.from,
        to: !e?.to ? 501 : e.to,
        value: e.value,
        key: e.key,
        unit: e.unit,
      };
    }) || [];
  return (
    <ButtonDrawerSlider
      label="Chiều dài"
      renderToValue={100}
      progress={50.5}
      sliderTitle={'Chiều dài (m)'}
      key={Math.random() + lengthFrom + lengthTo}
      deleteValue={() => {
        setValue('lengthKey', '');
        setValue('lengthFrom', 0);
        setValue('lengthTo', 101);
        setValue('areaKey', '');
        setValue('areaFrom', 0);
        setValue('areaTo', 20001);
      }}
      exceptValue={
        (lengthFrom === 0 || !lengthFrom) && lengthTo === 3
          ? '< 3 m'
          : lengthFrom === 100 && lengthTo === 101
          ? '> 100 m'
          : ''
      }
      theValueIsShow={100}
      listSelections={convertLength || []}
      onSelectItem={({ from, to, key }) => {
        setValue('lengthKey', key);
        setValue('lengthFrom', from);
        setValue('lengthTo', to);
        setValue('areaKey', '');
        setValue('areaFrom', 0);
        setValue('areaTo', 20001);
      }}
      keyValue={lengthKey}
      from={lengthFrom}
      to={lengthTo}
      unit={'m'}
      min={0}
      max={100}
      step={1}
      additionalItems={[{ key: '', from: 0, to: 101, value: 'Tất cả' }]}
    />
  );
}
