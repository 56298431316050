import React from 'react';
import { ReactChildren } from 'react';

type Props = {
  children?: ReactChildren,
  title?: string,
  close?: Function,
  renderRight?: Function,
};

export default function InnerAdvanceSearchWrapper({
  children,
  title,
  close,
  renderRight,
}: Props) {
  return (
    <div className="flex flex-col flex-1">
      <div
        className="w-full flex flex-col border-b pr-3 py-1 bg-white h-50px sticky top-0 z-10"
        style={{
          borderColor: '#e2e2e2',
          zIndex: 1000,
        }}>
        <div className="flex justify-center items-center">
          <div className="w-32px h-4px rounded-full bg-gray-500" />
        </div>
        <div className="flex flex-1 items-center justify-center">
          <button onClick={close} className="w-48px ">
            <img alt="down" src="/svg/rectangleDownIcon.svg" />
          </button>
          <div className="flex flex-1 justify-center items-center px-2 text-base font-bold">
            {title}
          </div>
          {!renderRight ? (
            <div className="w-40px" />
          ) : (
            renderRight && renderRight()
          )}
        </div>
      </div>
      <div className="flex-1">{children}</div>
    </div>
  );
}
