import Label from '@components/pages/PublishRealEstate/components/Lable';
import { Controller, useFormContext } from 'react-hook-form';
import DistrictDropdown from '@components/AddressDropdown/DistrictDropdown';

export default function DistrictButton() {
  const { control, setValue, watch } = useFormContext();
  const { province } = watch();
  return (
    <div className="w-full space-y-1">
      <Label labelContainerClassName="w-120px" label="Quận/ Huyện" />
      <Controller
        control={control}
        name="district"
        render={({ field: { value, onChange, ref } }) => {
          return (
            <div className="w-full">
              <DistrictDropdown
                ref={ref}
                key={Math.random()}
                isBigArrow
                provinceId={province}
                placeholder="Chọn giá trị"
                additionalItems={[{ id: '', name: 'Tất cả' }]}
                title="Quận/ Huyện"
                setValue={(nextValue) => {
                  onChange(nextValue);
                  setValue('ward', '');
                  setValue('street', '');
                }}
                value={value}
                onDelete={() => {
                  onChange('');
                  setValue('ward', '');
                  setValue('street', '');
                }}
              />
            </div>
          );
        }}
      />
    </div>
  );
}
