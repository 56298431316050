import React from 'react';
import { useFormContext } from 'react-hook-form';
import configs from '@utils/configs/RealEstateConfiguarations/RealEstateFilterConfigs.json';
import ButtonDrawerSlider from '@components/ButtonDrawerSlider';

type Props = {
  hideTitle?: boolean,
};

export default function PriceDrawerButton({ hideTitle }: Props) {
  const { setValue, watch } = useFormContext();
  const { priceKey, priceFrom, priceTo, isForSell } = watch();
  const { townhouse } = configs;
  const { priceToBuy, priceToRent } = townhouse || {};
  const convertPriceToBuy =
    priceToBuy?.map((e) => {
      return {
        from: e.from,
        to: !e?.to ? 101 : e.to,
        value: e.value,
        key: e.key,
        unit: e.unit,
      };
    }) || [];
  const convertPriceToRent =
    priceToRent?.map((e) => {
      return {
        from: e.from,
        to: !e?.to ? 200.5 : e.to,
        value: e.value,
        key: e.key,
        unit: e.unit,
      };
    }) || [];
  const listPrice =
    isForSell === 'ForRent' || isForSell === 'ToRent'
      ? convertPriceToRent
      : convertPriceToBuy || [];
  return (
    <ButtonDrawerSlider
      label="Giá"
      hideTitle={hideTitle}
      renderToValue={
        isForSell === 'ForRent' || isForSell === 'ToRent' ? 200 : 100
      }
      progress={isForSell === 'ForRent' || isForSell === 'ToRent' ? 100 : 25}
      sliderTitle={
        isForSell === 'ForRent' || isForSell === 'ToRent'
          ? 'Giá thuê (Triệu VNĐ)'
          : 'Giá bán (Tỷ VNĐ)'
      }
      key={Math.random() + priceFrom + priceTo}
      deleteValue={() => {
        setValue('priceKey', '');
        if (isForSell === 'ForRent' || isForSell === 'ToRent') {
          setValue('priceFrom', 0);
          setValue('priceTo', 200.5);
        } else {
          setValue('priceFrom', 0);
          setValue('priceTo', 101);
        }
      }}
      exceptValue={
        (priceFrom === 0 || !priceFrom) &&
        priceTo === 1 &&
        (isForSell === 'ForRent' || isForSell === 'ToRent')
          ? '<= 1 triệu'
          : priceFrom === 200 &&
            priceTo === 200.5 &&
            (isForSell === 'ForRent' || isForSell === 'ToRent')
          ? '>= 200 triệu'
          : priceFrom === 100 && priceTo === 101
          ? '> 100 tỷ'
          : ''
      }
      theValueIsShow={
        isForSell === 'ForRent' || isForSell === 'ToRent' ? 200 : 50
      }
      listSelections={listPrice}
      onSelectItem={({ from, to, key }) => {
        setValue('priceKey', key);
        setValue('priceFrom', from);
        setValue('priceTo', to);
      }}
      keyValue={priceKey}
      from={priceFrom}
      to={priceTo}
      unit={isForSell === 'ForRent' || isForSell === 'ToRent' ? 'triệu' : 'tỷ'}
      min={0}
      max={isForSell === 'ForRent' || isForSell === 'ToRent' ? 200 : 50}
      step={isForSell === 'ForRent' || isForSell === 'ToRent' ? 0.5 : 1}
      title="Giá"
      additionalItems={[
        {
          key: '',
          from: 0,
          to: isForSell === 'ForRent' || isForSell === 'ToRent' ? 200.5 : 101,
          value:
            isForSell === 'ForRent' || isForSell === 'ToRent'
              ? 'Tất cả giá thuê'
              : 'Tất cả giá bán',
        },
      ]}
    />
  );
}
