import React, { useEffect, useState } from 'react';
import ReactSliderComponentV2 from '@components/reactSliderComponentV2';
import Radio from '@components/Radio';
type SelectComponentProps = {
  configs?: Array,
  from?: number,
  to?: number,
  unit?: string,
  onSelectItem?: Function,
  min?: number,
  max?: number,
  step?: number,
  theValueIsShow?: number,
  stepRenderLine?: number,
  progress?: number,
  isLong?: boolean,
  showPlus?: boolean,
  show?: boolean,
  close?: Function,
  title?: string,
  wrapperStyle?: string,
};

export default function DrawerSliderSelection(props: SelectComponentProps) {
  const {
    configs,
    from,
    to,
    unit,
    onSelectItem,
    min = 0,
    max,
    step,
    theValueIsShow,
    stepRenderLine,
    title,
    progress,
    isLong,
    showPlus,
    show,
    close,
    wrapperStyle,
  } = props;

  const [initialFrom, setInitialFrom] = useState(from);
  const [initialTo, setInitialTo] = useState(to);
  const [isShowReactSlider, setShowReactSlider] = useState(false);
  useEffect(() => {
    if (show) {
      setTimeout(() => {
        setShowReactSlider(true);
      }, 100);
    } else {
      setShowReactSlider(false);
    }
  }, [show]);
  return (
    <div
      className={`w-full h-300px bg-white overflow-x-hidden ${wrapperStyle}`}>
      <div
        className="w-full flex flex-col border-b pr-3 py-1 bg-white sticky top-0 z-10 h-50px"
        style={{
          borderColor: '#e2e2e2',
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
        }}>
        <div className="flex justify-center items-center">
          <div className="w-32px h-4px rounded-full bg-gray-500" />
        </div>
        <div className="flex flex-1 items-center justify-center">
          <button onClick={close} className="w-48px ">
            <img alt="down" src="/svg/rectangleDownIcon.svg" />
          </button>
          <div className="flex flex-1 justify-center items-center px-2 text-base font-bold">
            {title}
          </div>
          <div
            onClick={() => {
              onSelectItem({
                from: initialFrom,
                to: initialTo,
              });
              close();
            }}
            className="text-sm text-blue-main font-bold">
            Xác nhận
          </div>
        </div>
      </div>
      <div className="w-full py-4">
        <div
          style={{ fontSize: 11, marginLeft: 16, marginTop: 12 }}
          className="font-bold">
          {title}
        </div>
        <div className="p-4 w-full">
          <div className="mt-6">
            {isShowReactSlider && (
              <ReactSliderComponentV2
                showPlus={showPlus}
                isLong={isLong}
                stepRenderLine={stepRenderLine}
                theValueIsShow={theValueIsShow}
                isAdvanceSearch
                setValue={(values) => {
                  setInitialFrom(values[0]);
                  setInitialTo(values[1]);
                }}
                max={max}
                min={min}
                step={step}
                unit={unit}
                currentFrom={initialFrom}
                currentTo={initialTo}
                minDistance={step}
                progress={progress}
              />
            )}
          </div>
        </div>
        <div className="flex-1 px-4 space-y-4 pb-4">
          {configs.map((item, index) => {
            const isActiveFromTo =
              initialFrom === item.from && initialTo === item.to;
            return (
              <Radio
                isChecked={isActiveFromTo}
                title={item.value}
                key={index}
                onPress={() => {
                  onSelectItem({ from: item.from, to: item.to, key: item.key });
                  close();
                }}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
