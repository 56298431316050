import React from 'react';
import { useQuery } from '@apollo/client';
import {
  GET_LIST_STREET_OF_DISTRICT,
  GET_LIST_STREETS_OF_ONE_WARD,
} from '@schemas/queries/map';
import MyDropdownForPublish from '@components/pages/PublishRealEstate/components/MyDropdownForPublish';
import { HCMId } from '@utils/configs/Common/CommonConfigs';
type Props = {
  value?: string,
  setValue?: Function,
  buttonDropdownClassName?: string,
  placeholder?: string,
  onDelete?: Function,
  additionalItems?: Array,
  errorMsg?: string,
  districtId: string,
  wardId: string,
  title?: string,
  isBigArrow?: boolean,
  disabled?: boolean,
  provinceId?: string,
};

function convertDataSelections(data) {
  return data?.map((item) => ({ key: item.id, value: item.name, ...item }));
}
export default React.forwardRef(StreetDropdown);
function StreetDropdown(props: Props, ref) {
  const {
    placeholder,
    value,
    setValue,
    buttonDropdownClassName,
    onDelete,
    additionalItems = [],
    errorMsg,
    districtId,
    wardId,
    title,
    isBigArrow = false,
    disabled,
    provinceId,
  } = props;
  let queryStreet,
    queryVariables = '';
  if (provinceId !== HCMId) {
    (queryStreet = GET_LIST_STREET_OF_DISTRICT),
      (queryVariables = { districtId, limit: 1500 });
  } else {
    (queryStreet = wardId
      ? GET_LIST_STREETS_OF_ONE_WARD
      : GET_LIST_STREET_OF_DISTRICT),
      (queryVariables = wardId
        ? {
            wardId,
            limit: 1500,
          }
        : { districtId, limit: 1500 });
  }
  const { data, loading } = useQuery(queryStreet, {
    variables: {
      ...queryVariables,
    },
    skip: !districtId && !wardId,
  });
  const finalData =
    wardId && provinceId === HCMId
      ? data?.getListStreetsOfOneWard?.streets
      : data?.getListStreetsOfDistrict?.streets;
  const rawData = [...(additionalItems || []), ...(finalData || [])];
  const dataSelections = convertDataSelections(rawData);

  return (
    <MyDropdownForPublish
      ref={ref}
      loading={loading}
      isBigArrow={isBigArrow}
      getObject
      title={title}
      isShowSearchBox={true}
      value={value}
      setValue={setValue}
      configs={dataSelections}
      placeholder={placeholder}
      buttonDropdownClassName={buttonDropdownClassName}
      errorMsg={errorMsg}
      onDelete={onDelete}
      disabled={disabled}
    />
  );
}
