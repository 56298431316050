import React, { useCallback } from 'react';
import Checkbox from '@components/checkbox';
import useList from 'react-use/lib/useList';
type SelectComponentProps = {
  configs?: Array,
  value?: string,
  setValue?: Function,
  close?: Function,
  title?: string,
  wrapperStyle?: string,
};

export default function DrawerMultiSelection(props: SelectComponentProps) {
  const { configs = [], value, setValue, close, title, wrapperStyle } = props;
  const [arr, { filter, push, set }] = useList(value || []);
  const isCheckedAll = arr?.length === configs?.length,
    isPartialCheckedAll = arr?.length > 0 && arr?.length < configs?.length;
  const onCheckedAll = useCallback(() => {
    if (isCheckedAll) {
      set([]);
    } else {
      set([...configs?.map((item) => item.key)]);
    }
  }, [isCheckedAll, configs, set]);

  const onCheckedItem = useCallback(
    (key, isChecked) => {
      if (isChecked) {
        filter((e) => e !== key);
      } else {
        push(key);
      }
    },
    [filter, push],
  );

  const onConfirm = useCallback(() => {
    setValue([...(arr || [])]);
    close();
  }, [close, setValue, arr]);
  return (
    <div className={`w-full h-300px bg-white ${wrapperStyle}`}>
      <div
        className="w-full flex flex-col border-b pr-3 py-1 bg-white fixed z-10 h-50px"
        style={{
          borderColor: '#e2e2e2',
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
        }}>
        <div className="flex justify-center items-center">
          <div className="w-32px h-4px rounded-full bg-gray-500" />
        </div>
        <div className="flex flex-1 items-center justify-center">
          <button onClick={close} className="w-48px ">
            <img alt="down" src="/svg/rectangleDownIcon.svg" />
          </button>
          <div className="flex flex-1 justify-center items-center px-2 text-base font-bold">
            {title}
          </div>
          <div onClick={onConfirm} className="text-sm text-blue-main font-bold">
            Xác nhận
          </div>
        </div>
      </div>
      <div style={{ marginTop: 50 }} className="w-full py-4">
        <div className="w-full px-6 overflow-scroll space-y-3">
          <Checkbox
            title="Tất cả"
            isChecked={isCheckedAll}
            isPartialChecked={isPartialCheckedAll}
            onPress={onCheckedAll}
          />
          {configs.map((item, index) => {
            const isChecked = arr.includes(item.key);
            return (
              <Checkbox
                key={index}
                title={item.value}
                wrapperStyle={{ marginBottom: 16 }}
                isChecked={isChecked}
                onPress={() => onCheckedItem(item.key, isChecked)}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
