import React from 'react';
import { useFormContext } from 'react-hook-form';
import configs from '@utils/configs/RealEstateConfiguarations/RealEstateFilterConfigs.json';
import ButtonDrawerSlider from '@components/ButtonDrawerSlider';
export default function RearDrawerButton() {
  const { setValue, watch } = useFormContext();
  const { rearKey, rearFrom, rearTo } = watch();
  const { townhouse } = configs;
  const { width } = townhouse || {};
  const convertWidth =
    width?.map((e) => {
      return {
        from: e.from,
        to: !e?.to ? 101 : e.to,
        value: e.value,
        key: e.key,
        unit: e.unit,
      };
    }) || [];
  return (
    <ButtonDrawerSlider
      label="Mặt hậu"
      renderToValue={100}
      progress={50.5}
      sliderTitle={'Mặt hậu (m)'}
      key={Math.random() + rearFrom + rearTo}
      deleteValue={() => {
        setValue('rearKey', '');
        setValue('rearFrom', 0);
        setValue('rearTo', 101);
      }}
      exceptValue={
        (rearFrom === 0 || !rearFrom) && rearTo === 3
          ? '< 3 m'
          : rearFrom === 100 && rearTo === 101
          ? '> 100 m'
          : ''
      }
      theValueIsShow={100}
      listSelections={convertWidth || []}
      onSelectItem={({ from, to, key }) => {
        setValue('rearKey', key);
        setValue('rearFrom', from);
        setValue('rearTo', to);
      }}
      keyValue={rearKey}
      from={rearFrom}
      to={rearTo}
      unit={'m'}
      min={0}
      max={100}
      step={1}
      additionalItems={[{ key: '', from: 0, to: 101, value: 'Tất cả' }]}
    />
  );
}
