import Label from '@components/pages/PublishRealEstate/components/Lable';
import styles from '@styles/components/PublistRealEstate/DropdownForPublish.module.scss';
import React, { useMemo, useState } from 'react';
import useLazyModal from '@hooks/common/useLazyModal';
import ArrowDown from '@public/svg/arrowV2.svg';
import DrawerSliderSelection from '@components/DrawerSliderSelection';
import DrawerWrapper from '@components/DrawerWrapper';

type ButtonDrawerSliderProps = {
  listSelections?: Array,
  to?: any,
  from?: any,
  unit?: string,
  min?: number,
  max?: number,
  minDistance?: number,
  onSelectItem?: Function,
  additionalItems?: Array,
  placeholder?: string,
  keyValue?: string,
  step?: number,
  disabled?: boolean,
  theValueIsShow?: string,
  exceptValue?: string,
  deleteValue?: boolean,
  stepRenderLine?: number,
  sliderTitle?: string,
  progress?: number,
  isLong?: boolean,
  showPlus?: boolean,
  renderToValue?: number,
  label?: string,
  hideTitle?: boolean,
};

export default function ButtonDrawerSlider(props: ButtonDrawerSliderProps) {
  const {
    listSelections = [],
    onSelectItem,
    keyValue,
    to,
    from,
    unit,
    min,
    max,
    step,
    additionalItems = [],
    theValueIsShow,
    exceptValue,
    deleteValue = () => {},
    stepRenderLine,
    sliderTitle,
    progress,
    isLong,
    showPlus = true,
    renderToValue: toValue,
    label,
    hideTitle,
  } = props;
  const [toggleDrawer, onToggleDrawer] = useState(false);
  const [disableDropdown, setDisableDropdown] = useState(false);
  const { renderModal, onHover } = useLazyModal(),
    selections = [...(additionalItems || []), ...(listSelections || [])],
    selectedValue = selections.find((e) => e.from === from && e.to === to);

  const renderUnit = useMemo(() => {
    const finalUnit =
      listSelections?.find((e) => e.key === keyValue)?.unit || unit;
    return to > max && showPlus ? '+ ' + ' ' + finalUnit : ' ' + finalUnit;
  }, [showPlus, keyValue, listSelections, max, to, unit]);

  const renderValueText = useMemo(() => {
    const renderToValue = to > toValue ? toValue : to;
    const renderValue = keyValue
      ? listSelections?.find((e) => e.key === keyValue)?.value
      : from + ' - ' + renderToValue + renderUnit;
    if ((!from && !to) || (from === min && to >= max)) {
      return 'Tất cả';
    } else {
      return exceptValue?.length > 0 ? exceptValue : renderValue;
    }
  }, [
    exceptValue,
    from,
    keyValue,
    listSelections,
    max,
    min,
    renderUnit,
    theValueIsShow,
    to,
  ]);
  return (
    <div className="w-full space-y-1">
      {!hideTitle && <Label labelContainerClassName="w-120px" label={label} />}
      <div className="w-full">
        <div
          onMouseEnter={onHover}
          onClick={() => onToggleDrawer(!toggleDrawer)}
          className={`${styles.buttonContainer} border-blue-main`}>
          <div className="w-full flex flex-row items-center justify-between">
            <div className={`flex-1 text-left text-sm truncate text-blue-main`}>
              {renderValueText}
            </div>
            <div className="flex flex-row items-center space-x-2 mr-2">
              {renderValueText !== 'Tất cả' && (
                <div
                  onClick={() => {
                    deleteValue && deleteValue();
                    setDisableDropdown(true);
                    setTimeout(() => {
                      setDisableDropdown(false);
                    }, 100);
                  }}
                  className="w-5 h-5 cursor-pointer">
                  <img
                    className="w-full h-full ml-1"
                    alt="close"
                    src="/svg/ico-close.svg"
                  />
                </div>
              )}
              <ArrowDown
                fill="#888"
                className={`${
                  toggleDrawer
                    ? 'transform rotate-180 duration-300'
                    : 'duration-300'
                }`}
              />
            </div>
          </div>
        </div>
        {(renderModal || toggleDrawer) && !disableDropdown && (
          <DrawerWrapper
            open={toggleDrawer}
            onClose={() => onToggleDrawer(false)}
            container={process.browser && document.getElementById('modal-root')}
            className="w-full h-full">
            <DrawerSliderSelection
              wrapperStyle="h-500px"
              close={() => onToggleDrawer(false)}
              show={toggleDrawer}
              from={from}
              to={to}
              key={keyValue}
              unit={unit}
              min={min}
              max={max}
              step={step}
              configs={listSelections}
              onSelectItem={(item) => onSelectItem(item)}
              selectedValue={selectedValue}
              theValueIsShow={theValueIsShow}
              stepRenderLine={stepRenderLine}
              title={sliderTitle}
              progress={progress}
              isLong={isLong}
              showPlus={showPlus}
            />
          </DrawerWrapper>
        )}
      </div>
    </div>
  );
}
