import Label from '@components/pages/PublishRealEstate/components/Lable';
import { Controller, useFormContext } from 'react-hook-form';
import CityDropdown from '@components/AddressDropdown/CityDropdown';

export default function CityButton() {
  const { control, setValue } = useFormContext();
  return (
    <div className="w-full space-y-1">
      <Label labelContainerClassName="w-120px" label="Tỉnh/ Thành phố" />
      <Controller
        control={control}
        name="province"
        render={({ field: { value, onChange, ref } }) => {
          return (
            <div className="w-full">
              <CityDropdown
                ref={ref}
                key={Math.random()}
                isBigArrow
                placeholder="Chọn giá trị"
                hideDeleteButton={false}
                additionalItems={[{ id: '', name: 'Tất cả' }]}
                title="Tỉnh/ Thành phố"
                setValue={(nextValue) => {
                  onChange(nextValue);
                  setValue('district', '');
                  setValue('ward', '');
                  setValue('street', '');
                }}
                value={value}
                onDelete={() => {
                  onChange('');
                  setValue('district', '');
                  setValue('ward', '');
                  setValue('street', '');
                }}
              />
            </div>
          );
        }}
      />
    </div>
  );
}
