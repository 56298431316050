import Label from '@components/pages/PublishRealEstate/components/Lable';
import { Controller, useFormContext } from 'react-hook-form';
import StreetDropdown from '@components/AddressDropdown/StreetDropdown';

export default function StreetButton() {
  const { control, watch } = useFormContext();
  const { district, ward } = watch();
  return (
    <div className="w-full space-y-1">
      <Label labelContainerClassName="w-120px" label="Đường" />
      <Controller
        control={control}
        name="street"
        render={({ field: { value, onChange, ref } }) => {
          return (
            <div className="w-full">
              <StreetDropdown
                ref={ref}
                key={Math.random()}
                isBigArrow
                wardId={ward}
                districtId={district}
                placeholder="Chọn giá trị"
                additionalItems={[{ id: '', name: 'Tất cả' }]}
                title="Đường"
                setValue={(nextValue) => {
                  onChange(nextValue.key);
                }}
                value={value}
                onDelete={() => {
                  onChange('');
                }}
              />
            </div>
          );
        }}
      />
    </div>
  );
}
