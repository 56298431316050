import React from 'react';
import { useFormContext } from 'react-hook-form';
import configs from '@utils/configs/RealEstateConfiguarations/RealEstateFilterConfigs.json';
import ButtonDrawerSlider from '@components/ButtonDrawerSlider';
export default function FloorDrawerButton() {
  const { setValue, watch } = useFormContext();
  const { floorCountKey, realEstateType, floorCountFrom, floorCountTo } =
    watch();
  const { townhouse } = configs;
  const { floorCount } = townhouse || {};
  const convertFloorCount =
    floorCount?.map((e) => {
      return {
        from: e.from,
        to: !e?.to ? 169 : e.to,
        value: e.value,
        key: e.key,
        unit: e.unit,
      };
    }) || [];
  return (
    <ButtonDrawerSlider
      label="Số tầng"
      renderToValue={168}
      progress={84.5}
      sliderTitle={'Số tầng'}
      key={Math.random() + floorCountFrom + floorCountTo}
      deleteValue={() => {
        setValue('floorCountKey', '');
        setValue('floorCountFrom', 0);
        setValue('floorCountTo', 169);
      }}
      exceptValue={
        floorCountFrom === 0 && floorCountTo === 3 ? '<= 3 tầng' : ''
      }
      disabled={
        realEstateType?.length === 1 &&
        (realEstateType?.[0] === 'building' || realEstateType?.[0] === 'land')
      }
      listSelections={convertFloorCount || []}
      onSelectItem={({ from, to, key }) => {
        setValue('floorCountKey', key);
        setValue('floorCountFrom', from);
        setValue('floorCountTo', to);
      }}
      keyValue={floorCountKey}
      from={floorCountFrom}
      to={floorCountTo}
      unit={'tầng'}
      min={0}
      max={168}
      step={1}
      additionalItems={[{ key: '', from: 0, to: 169, value: 'Tất cả số tầng' }]}
    />
  );
}
